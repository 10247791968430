import { combineReducers } from "redux"

import authenticationReducer from "./authentication";
import layoutReducer from "./layout";

const commonReducer = combineReducers({
    authentication: authenticationReducer,
    layout: layoutReducer,
});

export default commonReducer;
