export function getClaim(key) {
    let token_payload = parseTokenPayload(localStorage.getItem('kc-token'))

    if (token_payload === undefined) {
        console.log('could not parse token');
        return undefined;
    }

    return token_payload[key]
}

function parseTokenPayload(token) {
    let token_parts = token.split('.')
    if (token_parts.length < 2) {   // header, payload and maybe signature
        return undefined;
    }

    let decoded_token_payload = window.atob(token_parts[1])
    return JSON.parse(decoded_token_payload)
}

