import { fromMap } from "../../../../store/reducers/utils";
import update from "immutability-helper";
import actionTypes from "../actionTypes";

const configurationFetching = (state) =>
    update(state, {
        configuration: { $set: undefined },
        configurationFetching: { $set: true },
        configurationFetchFailureMessage: { $set: undefined },
    });

const configurationFetchSucceeded = (state, action) =>
    update(state, {
        configuration: { $set: action.configuration },
        configurationFetching: { $set: false },
        configurationFetchFailureMessage: { $set: undefined },
    });

const configurationFetchFailed = (state, action) =>
    update(state, {
        configuration: { $set: undefined },
        configurationFetching: { $set: false },
        configurationFetchFailureMessage: { $set: action.errorMessage },
    });


const reducers = {
    [actionTypes.sdk.configurationFetching]: configurationFetching,
    [actionTypes.sdk.configurationFetchSucceeded]: configurationFetchSucceeded,
    [actionTypes.sdk.configurationFetchFailed]: configurationFetchFailed,
}

const initialState = {
    configuration: undefined,
    configurationFetching: false,
    configurationFetchFailureMessage: false,
};

export default fromMap(reducers, initialState);
