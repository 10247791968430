import React from "react"

const Dashboard = React.lazy(() => import("./views/dashboard/Dashboard"))
const SdkConfiguration = React.lazy(() => import("./views/settings/Settings"))

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
    { path: "/tracking", name: "Sensing", component: Dashboard, exact: true },
    { path: "/tracking/sdk/configuration", name: "Tracking SDK Configuration", component: SdkConfiguration, exact: true },
]

export default routes
