import axios from "axios";
import qs from "querystring";

import { decodeToken } from "../utils/jwt";
import { createUUID } from "../utils/uuid";

const baseUrl = "https://identity.staging.gometroapp.com/auth/realms/platform";
const authorizeUrl = `${ baseUrl }/protocol/openid-connect/auth`;
const tokenUrl = `${ baseUrl }/protocol/openid-connect/token`;
const logoutUrl = `${ baseUrl }/protocol/openid-connect/logout`;
const redirectUrl = "http://localhost:3000/login";

const clientId = "gometro-platform-application";

/**
 * Logout
 */
const logout = () => {

    localStorage.removeItem("kc-callback");
    localStorage.removeItem("kc-refresh-token");
    localStorage.removeItem("kc-token");

    const request = { redirect_uri: redirectUrl };

    window.location.href = `${ logoutUrl }?${ qs.stringify(request) }`;
};

/**
 * Get Token
 *
 * @param request
 *
 * @returns {Promise<{realmAccess: *, user: {firstName: *, lastName: *, email: *}, resourceAccess: *, token: *}>}
 */
const getToken = (request) => {
    const options = { headers: { "Content-Type": "application/x-www-form-urlencoded" } };

    return axios
        .post(tokenUrl, qs.stringify(request), options)
        .then((response) => {
            const data = response.data;

            const refreshToken = data.refresh_token;
            const token = data.access_token;

            const decodedToken = decodeToken(token);

            localStorage.setItem("kc-refresh-token", refreshToken);
            localStorage.setItem("kc-token", token);

            return {
                realmAccess: decodedToken.realm_access,
                resourceAccess: decodedToken.resource_access,
                token: token,
                user: {
                    firstName: decodedToken.given_name,
                    lastName: decodedToken.family_name,
                    email: decodedToken.email
                },
            }
        });
};

/**
 * Update Token
 *
 * @param secondsLeft
 *
 * @returns {Promise<*>|Promise}
 */
const updateToken = (secondsLeft) => {

    const token = localStorage.getItem("kc-token");

    if (token) {

        const decodedToken = decodeToken(token);

        if (decodedToken.exp < (Date.now + secondsLeft * 1000)) {
            return Promise.resolve(token);
        }
    }

    return refreshToken()
        .then(userProfile => userProfile.token);
};

/**
 * Refresh Token
 *
 * @returns {Promise|Promise<{realmAccess: *, user: {firstName: *, lastName: *, email: *}, resourceAccess: *, token: *}>}
 */
const refreshToken = () => {

    const refreshToken = localStorage.getItem("kc-refresh-token");

    if (!refreshToken) {
        return Promise.reject("Could not find refreshToken in local storage!")
    }

    return getToken({
        client_id: clientId,
        grant_type: "refresh_token",
        refresh_token: refreshToken,
    });
};

/**
 * Authenticate with Provider
 *
 * @param loginOptions
 */
const authenticateWithProvider = (loginOptions) => {

    const nonce = createUUID();
    const state = createUUID();

    localStorage.setItem("kc-callback", JSON.stringify({state, redirectUrl}));

    const request = {
        client_id: clientId,
        kc_idp_hint: loginOptions.idpHint,
        redirect_uri: redirectUrl,
        response_type: "code",
        nonce: nonce,
        scope: "openid email profile",
        state: state
    };

    window.location.href = `${ authorizeUrl }?${ qs.stringify(request) }`;
};

/**
 * Authenticate with Credentials
 *
 * @param loginOptions
 *
 * @returns {Promise<{realmAccess: *, user: {firstName: *, lastName: *, email: *}, resourceAccess: *, token: *}>}
 */
const authenticateWithCredentials = (loginOptions) => {

    return getToken({
        username: loginOptions.username,
        password: loginOptions.password,
        client_id: "gometro-platform-application",
        grant_type: "password",
    });
};

/**
 * Complete Provider Authentication
 *
 * @returns {Promise|Promise<{realmAccess: *, user: {firstName: *, lastName: *, email: *}, resourceAccess: *, token: *}>}
 */
const completeProviderAuthentication = () => {

    const response = qs.parse(window.location.search.substring(1));
    const state = response.state;

    const callbackJson = localStorage.getItem("kc-callback");
    if (!callbackJson) {
        return Promise.reject("No callback data available!");
    }

    const callback = JSON.parse(callbackJson);
    if (callback.state !== state) {
        return Promise.reject("Response state does not match request!");
    }

    return getToken({
        code: response.code,
        grant_type: "authorization_code",
        redirect_uri: redirectUrl,
        client_id: clientId
    });
};

export default {
    authenticateWithCredentials,
    authenticateWithProvider,
    completeProviderAuthentication,
    logout,
    refreshToken,
    updateToken
};
