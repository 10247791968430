import React from "react"

//const Dashboard = React.lazy(() => import("./views/dashboard/Dashboard"))
const Accounts = React.lazy(() => import("./views/accounts/Accounts"))
const AccountAdd = React.lazy(() => import("./views/accounts/AccountAdd"))
const Projects = React.lazy(() => import("./views/projects/Projects"))
const ProjectAdd = React.lazy(() => import("./views/projects/ProjectAdd"))
const ProjectSettings = React.lazy(() => import("./views/projects/ProjectSettings"))
const ProjectSettingsEdit = React.lazy(() => import("./views/projects/ProjectSettingsEdit"))
const ProjectStats = React.lazy(() => import("./views/projects/ProjectStats"))
const ProjectCities = React.lazy(() => import("./views/projects/ProjectCities"))
const ProjectCityAdd = React.lazy(() => import("./views/projects/ProjectCityAdd"))
const ProjectCityView = React.lazy(() => import("./views/projects/ProjectCityView"))
const ProjectCityEdit = React.lazy(() => import("./views/projects/ProjectCityEdit"))

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
    { path: "/uma/accounts/:account/projects/add", name: "UMA Add Project", component: ProjectAdd },
    { path: "/uma/accounts/:account/projects/:project/stats", name: "UMA Project Statistics", component: ProjectStats },
    { path: "/uma/accounts/:account/projects/:project/edit", name: "UMA Edit Project Settings", component: ProjectSettingsEdit },
    { path: "/uma/accounts/:account/projects/:project/cities/add", name: "UMA Add City to Project", component: ProjectCityAdd },
    { path: "/uma/accounts/:account/projects/:project/cities/:region/view", name: "UMA City Settings", component: ProjectCityView },
    { path: "/uma/accounts/:account/projects/:project/cities/:region/edit", name: "UMA Edit Survey Region", component: ProjectCityEdit },
    { path: "/uma/accounts/:account/projects/:project/cities", name: "UMA Surveyed Cities", component: ProjectCities },
    { path: "/uma/accounts/:account/projects/:project", name: "UMA Project Settings", component: ProjectSettings },
    { path: "/uma/accounts/add", name: "UMA Add Account", component: AccountAdd },
    { path: "/uma/accounts/:account/projects", name: "UMA Projects", component: Projects },
    { path: "/uma/accounts", name: "UMA Accounts", component: Accounts },
]

export default routes
