import { combineReducers } from "redux"

import commonReducer from "../../modules/common/store/reducers";
import trackingReducer from "../../modules/tracking/store/reducers";

const rootReducer = combineReducers({
    common: commonReducer,
    tracking: trackingReducer,
});

export default rootReducer;
