import actionTypes from "../actionTypes";

export const configurationFetch = () =>
    ({ type: actionTypes.sdk.configurationFetch });

export const configurationFetching = () =>
    ({ type: actionTypes.sdk.configurationFetching });

export const configurationFetchSucceeded = (configuration) =>
    ({ type: actionTypes.sdk.configurationFetchSucceeded, configuration });

export const configurationFetchFailed = (errorMessage) =>
    ({ type: actionTypes.sdk.configurationFetchFailed, errorMessage });

/**
 * Export Actions
 */
export const actions = {
    configurationFetch,
    configurationFetching,
    configurationFetchSucceeded,
    configurationFetchFailed,
}
