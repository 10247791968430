import { takeEvery, all } from "redux-saga/effects";

import actionTypes from "../actionTypes";
import {
    configurationFetchSaga,
} from "./settings";

export function* watchTrackingSdk() {
    yield all([
        takeEvery(actionTypes.sdk.configurationFetch, configurationFetchSaga),
    ]);
}
