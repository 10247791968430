import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import "./scss/style.scss";
import PrivateRoute from "./modules/common/hoc/authentication/PrivateRoute";
import { getClaim } from "./modules/common/utils/tokenParser";

// Navigation
import navigation from "./modules/_nav";

// Routes
import commonRoutes from "./modules/common/routes";
import trackingRoutes from "./modules/tracking/routes";
import umaRoutes from "./modules/uma/routes";

import actions from "./modules/common/store/actions";

//@TODO add loading spinner to all pages with API requests?

const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"/>
    </div>
);

// Containers
const Layout = React.lazy(() => import("./modules/common/containers/Layout"));

// Pages
const Login = React.lazy(() => import('./modules/common/pages/login/Login'));

const App = (props) => {

    console.log("App", props);

    const {authenticated, completeAuthentication, initialisationFailed, initialised, initialise, initialising} = props;

    if (!authenticated && window.location.search && window.location.search.indexOf("code=") >= 0) {
        completeAuthentication();
        return null;
    }

    if (initialisationFailed) {
        // TODO Show a better error page
        return <h1>Something went wrong!!</h1>;
    }

    if (initialising) {
        // TODO Show a better loading page
        return <h1>Initialising...</h1>;
    }

    if (!initialised) {
        initialise(window.location.pathname);
        return null;
    }

    if (authenticated) {
        // Adjust UMA tab path based on account
        // Get accountId from token.
        const accountId = getClaim(`accountId`)

        function insertAt(array, index, ...elementsArray) { array.splice(index, 0, ...elementsArray)} 

        // If an account id exists in token, only load that account's projects
        if (accountId) {
            insertAt(navigation, 0, 
                {
                    _tag: "CSidebarNavItem",
                    name: "Dashboard",
                    to: `/uma/accounts/${accountId[0]}/projects`,
                    icon: "cil-speedometer"
                },
            )
            insertAt(navigation, 1, 
                {
                    _tag: "CSidebarNavItem",
                    name: "User Movement Analytics",
                    to: `/uma/accounts/${accountId[0]}/projects`,
                    icon: "cil-people"
                },
            )
        }
        else{   // otherwise, display all accounts (admin user)
            insertAt(navigation, 0, 
                {
                    _tag: "CSidebarNavItem",
                    name: "Dashboard",
                    to: `/uma/accounts/`,
                    icon: "cil-speedometer"
                },
            )
            insertAt(navigation, 1, 
                {
                    _tag: "CSidebarNavItem",
                    name: "User Movement Analytics",
                    to: `/uma/accounts`,
                    icon: "cil-people"
                },
            )
        }
    }
    

    return (
        <BrowserRouter>
            <React.Suspense fallback={ loading }>
                <Switch>
                    <Route
                        exact
                        path="/login"
                        name="Login Page"
                        render={ props => <Login { ...props }/> }/>
                    <PrivateRoute
                        name="User Movement Analytics"
                        path="/uma/*"
                        render={ props => <Layout { ...props } navigation={ navigation } routes={ umaRoutes }/> }/>
                    <PrivateRoute
                        name="Tracking"
                        path="/tracking"
                        render={ props => <Layout { ...props } navigation={ navigation } routes={ trackingRoutes }/> }/>
                    <PrivateRoute
                        name="Home"
                        path="/"
                        render={ props => <Layout { ...props } navigation={ navigation } routes={ commonRoutes }/> }/>
                </Switch>
            </React.Suspense>
        </BrowserRouter>
    );
};

const mapStateToProps = (state) => ({
    authenticated: state.common.authentication.authenticated,
    initialisationFailed: state.common.authentication.initialisationFailed,
    initialised: state.common.authentication.initialised,
    initialising: state.common.authentication.initialising,
});

const mapDispathToProps = (dispatch) => ({
    completeAuthentication: () => dispatch(actions.authentication.completeAuthentication()),
    initialise: (redirectPath) => dispatch(actions.authentication.initialise(redirectPath)),
});

export default connect(mapStateToProps, mapDispathToProps)(App);
