import React from "react"

//@TODO add breadcrumbs here for subheader navigation?

const Dashboard = React.lazy(() => import("./views/dashboard/Dashboard"))

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
    { path: "/", exact: true, name: "Home" },
    { path: "/dashboard", name: "Dashboard", component: Dashboard },
]

export default routes
