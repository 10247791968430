import * as React from "react";
import { Redirect, Route } from "react-router-dom";

import { useSelector } from "react-redux";

const PrivateRoute = ({ render: Component, roles, ...rest }) => {

    const authenticated = useSelector(state => state.common.authentication.authenticated);

    // const isAuthorized = (roles) => {
    //     if (keycloak && roles) {
    //         return roles.some(role =>
    //             keycloak.hasRealmRole(role) || keycloak.hasResourceRole(role)
    //         );
    //     }
    //     return false;
    // }

    return (
        <Route
            { ...rest }
            render={ props =>
                (
                    authenticated ?
                        <Component { ...props } /> :
                        <Redirect to={ { pathname: "/login", state: { from: props.location } } }/>
                )
            }
        />
    );
};

export default PrivateRoute;
