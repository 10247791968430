export default [
    {
        _tag: "CSidebarNavDropdown",
        name: "Tracking",
        to: "/tracking",
        icon: "cil-map-alt",
        _children: [
            {
                _tag: "CSidebarNavItem",
                name: "SDK Configuration",
                to: "/tracking/sdk/configuration",
            },
        ]
    }
]
