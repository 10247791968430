import { put } from "redux-saga/effects";

import axios from "../../../../config/axios";
import actions from "../actions";

/**
 * Configuration Fetch
 */
export function* configurationFetchSaga() {
    yield put(actions.sdk.configurationFetching());

    try {
        const response = yield axios.get("/tracking/v1/configuration");
        const configuration = response.data;

        yield put(actions.sdk.configurationFetchSucceeded(configuration));
    } catch (error) {
        yield put(actions.sdk.configurationFetchFailed(error.message));
    }
}

