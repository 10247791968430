import { sygnet } from "./sygnet"
import { logo } from "./logo"
import { logoNegative } from "./logo-negative"

import {
    cibGoogle,
    cilAccountLogout,
    cilApplicationsSettings,
    cilLockLocked,
    cilMapAlt,
    cilMoon,
    cilPeople,
    cilSpeedometer,
    cilSun,
    cilUser,
} from "@coreui/icons-pro"

export const icons = Object.assign({}, {
    sygnet,
    logo,
    logoNegative
}, {
    cibGoogle,
    cilAccountLogout,
    cilApplicationsSettings,
    cilLockLocked,
    cilMapAlt,
    cilMoon,
    cilPeople,
    cilSpeedometer,
    cilSun,
    cilUser,
})
